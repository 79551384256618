import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import "./BriefExams.css"
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function BriefExams() {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/exams', { state: { highSchool: 'true' ,cat : ["103" , "104"]} });
  };

  return (
    <Container className='brief-exams'>
            <Row className="mt-5 mx-5">
            <Col xs={6}>
              <div className="ready-exam1">
                <h6> لا تـحـمـل هـم التـحصيلـي والقـدرات </h6>
                <button onClick={handleClick}> جرب الأن </button>
              </div>
            </Col>
            <Col xs={6}>
              <div className="ready-exam2">
                <h6> جــاهــز للثــانــويــة العــامــة ؟ </h6>
                <Link to="/packages">
                <button> جرب الأن </button>
                </Link>
              </div>
            </Col>
          </Row>
    </Container>
   
  )
}

export default BriefExams
